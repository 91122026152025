import { get, post } from '../request'
/**车单管理模块 */
export default {
    /**
     * @description 查询所有工单列表
     * @param {Object} params 
     * @returns Object
     */
    getAllOrder(params) {
        return get("/caWorkOrder/selectCaWorkOrderAll",params)
    },
    /**
     * @description 添加工单
     * @param {Object} params 
     * @returns Object
     */
     addOrder(params) {
        return post("/caWorkOrder/addCaWorkOrder",params)
    },
    /**
     * @description 修改工单
     * @param {Object} params 
     * @returns Object
     */
     editOrder(params) {
        return post("/caWorkOrder/updateCaWorkOrder",params)
    },
    /**
     * @description 查询单个工单
     * @param {Number} params 
     * @returns Object
     */
     getOneOrder(params) {
        return get("/caWorkOrder/getCaWorkOrderDetails?id="+params)
    },
    /**
     * @description 删除工单
     * @param {Number} params 
     * @returns Object
     */
     delOrder(params) {
        return get("/caWorkOrder/deleteCaWorkOrder?id="+params)
    },
    /**
     * @description 作废工单
     * @param {Number} params 
     * @returns Object
     */
    voidOrder(params) {
        return get("/caWorkPayOrder/cancelCaWorkOrder?id="+params)
    },
    /**
     * @description 确认工单
     * @param {Object} params 
     * @returns Object
     */
    verifyOrder(params) {
        return get("/caWorkOrder/confirmCaWorkOrder",params)
    },
    /**
     * @description 修改工单内的车项目状态
     * @param {object} params 
     * @returns Object
     */
    verifyOrderItem(params) {
        return get("/caWorkOrderItems/updateCaWorkOrderItemsState",params)
    },
    /**
     * @description 查询工单的支付记录
     * @param {number} params 
     * @returns Object
     */
    getPayOrder(params) {
        return get("/caWorkPayOrder/selectAll",params)
    },
    /**
     * @description 添加工单的项目
     * @param {number} params 
     * @returns Object
     */
    addOrderItem(params) {
        return post("/caWorkOrderItems/addCaWorkOrderItems",params)
    },
    /**
     * @description 修改工单的项目
     * @param {Object} params 
     * @returns Object
     */
    editOrderItem(params) {
        return post("/caWorkOrderItems/updateCaWorkOrderItems",params)
    },
    /**
     * @description 删除工单的项目
     * @param {number} params 
     * @returns Object
     */
    delOrderItem(params) {
        return get("/caWorkOrderItems/deleteCaWorkOrderItems?id="+params)
    },
    /**
     * @description 修改工单的项目状态
     * @param {number} params 
     * @returns Object
     */
    verifyOrderItemState(params) {
        return get("/caWorkOrderItems/updateCaWorkOrderItemsState?id="+params)
    },

    /***工单退款 */
    WorkerOrderRefund(param){
        return get(`/caWorkPayOrder/caWorkOrderRefund`,param)
    },
    /**
     * @description 查询工单金额
     * @param {number} params 
     * @returns Object
     */
    orderMoney(param){
        return get('/caWorkOrder/confirmCaWorkOrderMoney?workOrderNumber='+param);
    },
    /**
     * @description 工单支付
     * @param {Object} params 
     * @returns Object
     */
     orderPay(param){
        return post('/caWorkPayOrder/caWorkOrderPayWay',param);
    },
    /**
     * @description 工单支付信息
     * @param {object} params 
     * @returns Object
     */
     orderPayMoney(param){
        return get('/caWorkPayOrder/caWorkOrderPayMoney',param);
    },
    /**
     * @description 查询混合支付订单
     * @param {Object} params 
     * @returns Object
     */
    selectAllHunhe(param){
        return get('/caWorkPayOrderHunhe/selectCaWorkPayOrderHunheAll',param);
    },
    /**
     * @description 混合支付订单退款
     * @param {number} params 
     * @returns Object
     */
    orderHunheRefund(param){
        return get('/caWorkPayOrderHunhe/caWorkPayOrderHunheRefund?id='+param);
    },
    /**
     * @description 混合支付一次退款
     * @param {number} params 
     * @returns Object
     */
     moreOrderHunheRefunds(param){
        return get('/caWorkPayOrderHunhe/caWorkPayOrderHunheRefunds?outOrderNo='+param);
    },
    /**
     * @description 混合支付订单信息
     * @param {number} params 
     * @returns Object
     */
     moreOrderHunheInfo(param){
        return get('/caWorkPayOrderHunhe/caWorkPayOrderHunheRefundList?outOrderNo='+param);
    },

    /** 支付工单优惠券列表  */
    queryCouponListOfWorkOrder(param){
        return get(`/caWorkPayOrder/normalCouponCanUse`,param)
    },
    /**
     * @description 查询工单详情
     * @param {number} params 
     * @returns Object
     */
     selectByWorkOrderNumber(params){
        return post('/caWorkPayOrder/selectByWorkOrderNumber',params)
    }
}