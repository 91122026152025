// 引入axios
import axios from "axios";
import store from "@/store";
import { ElMessage } from "element-plus";
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/config/serviceLoading"; //辅助函数和枚举
console.log("request", store.getters.GET_BASEURL);
// 创建axios实例
const httpService = axios.create({
  // url前缀-'http:xxx.xxx'
  // baseURL: process.env.BASE_API, // 需自定义
  // baseURL: baseUrl,
  // 请求超时时间
  // timeout: 20000, // 需自定义
  timeout: 180000, // 需自定义
});

//添加请求和响应拦截器
// 添加请求拦截器
httpService.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    //config.headers.token=window.sessionStorage.getItem('token');
    // console.log("store="+store.getters.GET_TOKEN)
    config.headers.Authorization = store.getters.GET_TOKEN;
    config.headers.tenantId = store.getters.GET_TENANTID
      ? store.getters.GET_TENANTID
      : store.state.tenantId; // 将油站id加入到请求头中  第一次登录是 kng
    // console.log("tenantid=" + store.getters.GET_TENANTID)
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
let tokenFail = false;
// 添加响应拦截器
httpService.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data.code === 401) {
      store.commit("SET_ROUTES_STATE", false);
      store.commit("RESET_TABS");
      store.dispatch("logout");
      if (tokenFail) {
        return;
      }
      tokenFail = true;
      setTimeout(() => {
        tokenFail = false;
      }, 3000);
      ElMessage.error("登录信息已过期,请重新登录");
    }
    // 不是20000成功并有提示
    else if (
      response.data.message &&
      response.data.code !== 20000 &&
      response.data.code !== 2002 &&
      response.data.code !== 3002
    ) {
      ElMessage.error(`${response.data.message}`);
    }
    //返500 提示也是null
    else if (response.data.code === 500 && !response.data.message) {
      ElMessage.error("操作失败,请稍后再试!");
    }
    // 不是200成功并有提示
    else if (response.data.msg && response.data.code !== 200) {
      ElMessage.error(`${response.data.msg}`);
    }

    return response;
  },
  function (error) {
    if (tokenFail) {
      return;
    }
    tokenFail = true;
    setTimeout(() => {
      tokenFail = false;
    }, 3000);
    // 对响应错误做点什么
    if (error.code === "ECONNABORTED" && error.message.includes("timeout")) {
      ElMessage.error("请求超时，请稍后再试");
    } else if (error.message.indexOf("Network Error") !== -1) {
      ElMessage.error("网络错误！请您稍后重试");
    } else if (error.response.status === 404) {
      ElMessage.error("资源未找到");
    } else {
      ElMessage.error(error.message || "请求发生错误");
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

/*网络请求部分*/

/**
 *
 * @description {Method} get             get请求
 * @param {String} url             请求地址
 * @param {String|Object} params   defualt Object
 * @returns    Object
 */
export function get(url, params = {}, isLoading = false, type = "json") {
  return new Promise((resolve, reject) => {
    if (isLoading) {
      showFullScreenLoading();
    }

    httpService({
      url: store.getters.GET_BASEURL + url,
      method: "get",
      params: params,
      headers: {
        "Content-Type":
          type == "form"
            ? "application/x-www-form-urlencoded"
            : "application/json",
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
      .finally(() => {
        // 完成 或失败 都执行的方法
        tryHideFullScreenLoading();
      });
  });
}

/**
 *
 * @description {Method} post            post请求
 * @param {String} url             请求地址
 * @param {String|Object} params   defualt Object
 * @returns  Object
 */
export function post(url, params = {}, isLoading = false, type = "json") {
  return new Promise((resolve, reject) => {
    if (isLoading) {
      showFullScreenLoading();
    }
    httpService({
      url: store.getters.GET_BASEURL + url,
      method: "post",
      data: params,
      headers: {
        "Content-Type":
          type == "form"
            ? "application/x-www-form-urlencoded"
            : "application/json",
      },
    })
      .then((response) => {
        //console.log(response)
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
      .finally(() => {
        tryHideFullScreenLoading();
      });
  });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}, isLoading = false) {
  return new Promise((resolve, reject) => {
    if (isLoading) {
      showFullScreenLoading();
    }
    httpService({
      url: store.getters.GET_BASEURL + url,
      method: "post",
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        tryHideFullScreenLoading();
      });
  });
}

export function getServerUrl() {
  return store.getters.GET_BASEURL;
}

export default {
  get,
  post,
  fileUpload,
  getServerUrl,
};
