import { get, post } from "../request";
/**统计管理模块 */
export default {
  /**
   * @description 计算员工的提成金额
   * @param {Object} params
   * @returns Object
   */
   summaryEmployeeCommission(params) {
    return get("/caWorkOrder/summaryEmployeeCommission", params);
  },
};
