import { get, post } from '../request'
/**提成管理模块 */
export default {
    /**
     * @description 新增-更新提成配置
     * @param {Object} params 
     * @returns Object
     */
    editRoyalty(params) {
        return  post("/acquiesce/royalty",params)
    },
    /**
     * @description 查询单个提成配置
     * @param {Object} params 
     * @returns Object
     */
     selectOneRoyalty(params) {
        return  get("/acquiesce/royalty/select?id="+params)
    },
    /**
     * @description 查询配置
     * @param {Object} params 
     * @returns Object
     */
    selectAllRoyalty() {
        return  get("/acquiesce/royalty")
    },
    /**
    * @description 查询提成配置已经员工信息
    * @param {Object} params 
    * @returns Object
    */
     getRoyalty() {
       return  get("/acquiesce/royalty/getRoyalty")
   },
}